import 'react-app-polyfill/stable'
import 'core-js'
// import 'localstorage-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store, persistor } from './store/index'
import { PersistGate } from 'redux-persist/integration/react'
import history from './module/history'
import { registerPlugin } from 'react-filepond'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import SocketProvider from './containers/SocketProvider'
import OnlineProvider from './containers/OnlineProvider'

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const charAT = `

 █████╗ ██╗██████╗  █████╗ ███╗   ██╗    ██████╗ ███████╗██╗   ██╗
██╔══██╗██║██╔══██╗██╔══██╗████╗  ██║    ██╔══██╗██╔════╝██║   ██║
███████║██║██║  ██║███████║██╔██╗ ██║    ██║  ██║█████╗  ██║   ██║
██╔══██║██║██║  ██║██╔══██║██║╚██╗██║    ██║  ██║██╔══╝  ╚██╗ ██╔╝
██║  ██║██║██████╔╝██║  ██║██║ ╚████║    ██████╔╝███████╗ ╚████╔╝ 
╚═╝  ╚═╝╚═╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝    ╚═════╝ ╚══════╝  ╚═══╝  
 `;
console.info(`%c${charAT}`, 'color: #5BE49B');

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}



ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={loading} persistor={persistor}>
      <SocketProvider>
        <OnlineProvider >
          <App history={history} />
        </OnlineProvider>
      </SocketProvider>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register();
}
if (process.env.NODE_ENV === 'development') {
  serviceWorkerRegistration.unregister();
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
reportWebVitals()