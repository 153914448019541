import keyMirror from 'keymirror'
/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  COLAPSE_STATE: undefined,
  SWITCH_MENU: undefined,
  EXCEPTION: undefined,
  USER_LOGIN: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  GITHUB_GET_REPOS: undefined,
  GITHUB_GET_REPOS_SUCCESS: undefined,
  GITHUB_GET_REPOS_FAILURE: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
  //
  USER_AUTH: undefined,
  USER_AUTH_LOGOUT: undefined,
  USER_AUTH_FAILURE: undefined,
  //
  USER_REGISTER: undefined,
  USER_REGISTER_FAILED: undefined,
  USER_REGISTER_SUCCESS: undefined,
  // private Optional
  PRIVATE_POST_DOCUMENT: undefined,
  PRIVATE_POST_DOCUMENT_SUCCESS: undefined,
  PRIVATE_POST_DOCUMENT_FAILURE: undefined,
  // Menu
  MENU_SIDEBAR_GET: undefined,
  MENU_SIDEBAR_SUCCESS: undefined,
  MENU_SIDEBAR_FAILED: undefined,
  MENU_SIDEBAR_SETDRAG: undefined,
  MENU_SIDEBAR_SETDRAG_SUCCESS: undefined,
  MENU_SIDEBAR_SETDRAG_FAILED: undefined,
  // modal
  MENU_MODAL_SHOW: undefined,
  MENU_MODAL_HIDE: undefined,
  // penduduk
  GET_DATA_PENDUDUK: undefined,
  GET_DATA_PENDUDUK_SUCCESS: undefined,
  GET_DATA_PENDUDUK_FAILURE: undefined,
  // addd
  ADD_DATA_PENDUDUK: undefined,
  ADD_DATA_PENDUDUK_SUCCESS: undefined,
  ADD_DATA_PENDUDUK_FAILURE: undefined,
  // GET WILAYAH
  GET_WILAYAH: undefined,
  GET_WILAYAH_SUCCESS: undefined,
  GET_WILAYAH_FAILURE: undefined,
  // set Filter Wilaya
  FILTER_WILAYAH_SET: undefined,
  FILTER_WILAYAH_GET: undefined,

  // Add USer
  ADD_USER: undefined,
  ADD_USER_SUCCESS: undefined,
  ADD_USER_FAILED: undefined,
  // axios
  AXIOS_STATE: undefined,
  AXIOS_FAKE_RUNNING: undefined,
  AXIOS_STATE_SUCCESS: undefined,
  AXIOS_STATE_FAILURE: undefined,
  AXIOS_STATE_PROFILE: undefined,
  // selected_item
  SELECTED_ITEM_SET: undefined,
  SELECTED_ITEM_PRINT: undefined,
  // setting user
  SETTING_STATE: undefined,
  RESET_SETING_STATE: undefined,

  SETTING_STATE_SUCCESS: undefined,
  SETTING_STATE_UPDATESETTING: undefined,
  SETTING_STATE_FAILED: undefined,
  SETTING_STATE_SETPROFILE: undefined,

  //
  REQUEST_RUN: undefined,
  REQUEST_RUN_START: undefined,
  REQUEST_RUN_SUCCESS: undefined,

  REQUEST_RUN_FAILURE: undefined,
  REQUEST_RUN_RESET: undefined,
  REQUEST_RUN_RESET_NON_ADMIN: undefined,
  REQUEST_RUN_RESET_POST_IDLE: undefined,


  PARSIAL_RUN_RESET: undefined,
  PARSIAL_RUN: undefined,
  PARSIAL_RUN_START: undefined,
  PARSIAL_RUN_PREPARE: undefined,
  PARSIAL_RUN_SUCCESS: undefined,
  PARSIAL_RUN_FAILURE: undefined,


  REQUEST_RUN_FAKE: undefined,
  REQUEST_RUN_FAKE_SUCCESS: undefined,
  REQUEST_RUN_FAKE_FAILURE: undefined,

  PROFILE_SET: undefined,

  PROFILE_SET_SUCCESS: undefined,
  PROFILE_SET_FAILED: undefined,
  PROFILE_SET_RESET: undefined,
  PROFILE_SET_FOTO: undefined,

  PROFILE_UPDATE: undefined,
  PROFILE_UPDATE_SUCCESS: undefined,
  PROFILE_UPDATE_FAILED: undefined,
  // profile upload
  PROFILE_UPLOAD_IMAGE: undefined,
  PROFILE_UPLOAD_IMAGE_SUCCESS: undefined,
  PROFILE_UPLOAD_IMAGE_FAILED: undefined,

  FOTO_COLLECTION_GET: undefined,
  FOTO_COLLECTION_GET_SUCCESS: undefined,
  FOTO_COLLECTION_GET_FAILED: undefined,
  FOTO_COLLECTION_SELECT: undefined,

  FOTO_COLLECTION_DELETE: undefined,
  FOTO_COLLECTION_DELETE_SUCCESS: undefined,
  FOTO_COLLECTION_DELETE_FAILED: undefined,

  MODAL_SECOND_SHOW: undefined,
  MODAL_SECOND_HIDE: undefined,

  MODAL_TIGA_SHOW: undefined,
  MODAL_TIGA_CHANGE_STYLE: undefined,
  MODAL_TIGA_HIDE: undefined,


  TOGGLE_HIDDEN_FIELD: undefined,
})

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const WILAYAH_METHOD = {
  DESA: 'desa',
  KECAMATAN: 'kecamatan',
  DUKUH: 'dukuh',
  KABUPATEN: 'kabupaten',
}

export const RESPONSE = {
  SUKSESS: 'suksess',
  ERROR: 'error',
}
