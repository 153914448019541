/**
 * @module Sagas/SettingUser
 * @desc SettingUser
 */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { request } from 'src/helpers/request'
import { ActionTypes } from '../constants'

export function* getSetting() {
  try {
    const res = yield call(request, 'get', '/pengaturan/profile?is_cek_profile=true')
    // console.log(res)
    const { data } = res
    // console.log(data)
    yield put({
      type: ActionTypes.PROFILE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (err) {
    // console.log(err)
    const ret = err?.response || null
    if (ret && ret.status === 401) {
      // alert('tekan keneh')
      localStorage.setItem('authToken', '')
      return yield put({
        type: ActionTypes.USER_AUTH_FAILURE,
        redirect: '/login',
      })
    }
    yield put({
      type: ActionTypes.PROFILE_UPDATE_FAILED,
      response: ret,
    })
  }
}
/**
 * SettingUser Sagas
 */
export default function* root() {
  yield all([takeLatest(ActionTypes.PROFILE_UPDATE, getSetting)])
}
