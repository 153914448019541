import { ENDPOINT } from './config'
// import Reaft fro
import socketIOClient from 'socket.io-client'
import React from 'react'
import { GetToken } from '../helpers/request'

const token = GetToken()

const create_socket = (id_reseller) => {
  return socketIOClient(ENDPOINT, {
    transports: ['websocket'],
    autoConnect: false,
    auth: {
      ...(token && {
        Authorization: 'Bearer ' + token?.token,
      }),
    },
    // withCredentials: true,
    transportOptions: {
      polling: {
        extraHeaders: {
          ...(token && {
            Authorization: 'Bearer ' + token?.token,
          }),
          MyIdReseller: id_reseller,
        },
      },
    },
  })
}

export const SocketIoClient = (id_reseller) => ({
  socket: create_socket(id_reseller),
})

// SocketIoClient.connect()
// SocketIoClient.on()

export const SocketContext = React.createContext({})
