/**
 * @file reducers/content.js
 * @author Hanif Permana <nifqi18@yahoo.com>
 * Date: 16.12.2016
 */

// import { ActionTypes } from '../constants'

/* eslint-env browser */
const ParsialRequest = (state = [], action) => {
    // console.log(action)
    //
    const { response, lastaction } = action

    switch (action.type) {
        case 'PARSIAL_RUN_RESET':
            return Object.assign({}, state, { status: 'reset', lastaction: {} })
        case 'PARSIAL_RUN':
            return Object.assign({}, state, { status: 'idle' })
        case 'PARSIAL_RUN_START':
            return Object.assign({}, state, { status: 'running', lastaction })
        case 'PARSIAL_RUN_PREPARE':
            return Object.assign({}, state, { status: 'prepare', lastaction, response })
        case 'PARSIAL_RUN_SUCCESS':
            return Object.assign({}, state, { status: 'success', response, lastaction })
        case 'PARSIAL_RUN_FAILURE': {
            return Object.assign({}, state, { status: 'error', response, lastaction })
        }

        default: {
            return state
        }
    }
}

const PrepareRequest = (state = {}, action) => {
    if (typeof action?.reduxUid === 'undefined' && action?.type !== 'PARSIAL_RUN_RESET_NON_ADMIN')
        return state

    if (action?.type === 'PARSIAL_RUN_RESET_NON_ADMIN') {
        return {
            get_filter_administrator: state.get_filter_administrator,
        }
    }


    const { reduxUid } = action
    const newState = Object.assign({}, state)

    newState[reduxUid] = ParsialRequest(state[reduxUid], action)

    return newState
}

export default PrepareRequest
