// import { ActionTypes } from 'constants/index';

import { ActionTypes } from '../constants'

export const MultiRequest = (reduxUid, { method, url, payload, dataPost }) => ({
  type: ActionTypes.REQUEST_RUN,
  reduxUid,
  payload: { method, url, payload, dataPost },
})

export const KirimNotifikasi = ({ dataPost }) => ({
  type: ActionTypes.REQUEST_RUN,
  reduxUid: 'notifikasi',
  payload: { method: 'post', url: '/tagihan/notif', dataPost },
})

export const FakeMultiRequestRun = (reduxUid, { method, url, payload, data }) => ({
  type: ActionTypes.REQUEST_RUN_FAKE,
  reduxUid,
  payload: { method, url, payload, data },
  lastaction: { method, url, payload },
})

export const FakeMultiRequestSuccess = (reduxUid, { response }) => ({
  type: ActionTypes.REQUEST_RUN_FAKE_SUCCESS,
  reduxUid,
  response,
})

export const FakeMultiRequestFailed = (reduxUid, { method, url, payload, data }) => ({
  type: ActionTypes.REQUEST_RUN_FAKE_FAILURE,
  reduxUid,
  payload: { method, url, payload, data },
})

export const ResetMultiRequest = (reduxUid) => ({
  type: ActionTypes.REQUEST_RUN_RESET,
  reduxUid,
})

export const ResetMultiRequestNonAdministrator = (reduxUid) => ({
  type: ActionTypes.REQUEST_RUN_RESET_NON_ADMIN,
  reduxUid,
})
export const ResetMultiRequestPostToIdle = (reduxUid) => ({
  type: ActionTypes.REQUEST_RUN_RESET_POST_IDLE,
  reduxUid,
})
