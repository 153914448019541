/**
 * @module Sagas/Axios
 * @desc Axios
 */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'src/helpers/request';
// import { ActionTypes } from 'constants/index';
// import { request } from 'modules/helpers';
import { ActionTypes } from '../constants';

export function* RequestWeb({ method, url, postdata, action, responseType }) {

    try {
        const res = yield call(request, method, `${url}`, postdata, responseType);
        const { data } = res;
        // console.log
        // console.log(res);
        // console.log(url)

        if (url === 'foto/setprofile' && postdata.action === 'foto_profile') {
            yield put({
                type: ActionTypes.PROFILE_SET_FOTO,
                response: data,
            });
        }

        if (url === 'foto/setprofile' && postdata.action === 'setting_foto') {
            yield put({
                type: ActionTypes.SETTING_STATE_SETPROFILE,
                response: data,
            });
        }

        if (url === 'foto/delete') {
            yield put({
                type: ActionTypes.FOTO_COLLECTION_GET_SUCCESS,
                response: data,
            });
        }



        yield put({
            type: ActionTypes.AXIOS_STATE_SUCCESS,
            response: data,
            action: { url, method, postdata, action },
        });

        if (url === '/pengaturan/aplikasi' && method === "post") {
            yield put({
                type: ActionTypes.SETTING_STATE_UPDATESETTING,
                response: data,
            });
        }

    }
    catch (err) {
        // console.log(err)
        const result = err.response !== undefined ? err.response.statusText : null;
        const ret = err.response !== undefined ? err.response : 'error';
        // console.log(location.pathname);

        if (ret?.status === 401) {
            // location.reload();

            return yield put({
                type: ActionTypes.USER_AUTH_FAILURE,
                redirect: window.location.pathname,
            });
        }

        yield put({
            type: ActionTypes.AXIOS_STATE_FAILURE,
            response: ret,
            action: { url, method, postdata, action },
        });
    }
}

// export function* TestAxiosSuccessParser({ action, response }) {
//   // console.log(action)

//   if (action.method === 'post' && action.url === '/pengaturan/moduleaktif') {
//     // alert('crot')
//     yield put({
//       type: ActionTypes.SETTING_STATE_SUCCESS,
//       response: {
//         data: response.data.pengaturan
//       },
//       action: action,
//     });
//   }
// }
/**
 * Axios Sagas
 */
export default function* root() {
    yield all([takeLatest(ActionTypes.AXIOS_STATE, RequestWeb)]);
    // yield all([takeLatest(ActionTypes.AXIOS_STATE_SUCCESS, TestAxiosSuccessParser)]);
}
