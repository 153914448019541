import moment, * as Moment from 'moment'
import 'moment/locale/id'

export const isDevelopment = process.env.NODE_ENV === "development"

async function getWilayah() {
  try {
    let response = await fetch('/json/wilayah.json')
    let responseJson = await response.json()
    return responseJson
  } catch (error) {
    console.error(error)
  }
}


export const formatRupiah = (angka, prefix) => {
  let separator
  if (!angka) {
    if (prefix) return 'Rp. 0'
    return '0'
  }

  // const _isNegatif = angka < 1

  // console.log(_isNegatif)

  var number_string = angka
    .toString()
    .replace(/[^,\d]/g, '')
    .toString(),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi)

  if (ribuan) {
    separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah

  // console.log(rupiah)

  return prefix === undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : ''
}

export function formatRupiahtoNumber(currency) {
  const _currency = currency.toString().replace('Rp. ', '')
  const number = Number(_currency.replace(/./g, ''))
  return number
}

export const isJSON = (object) => {
  let a = null
  try {
    a = JSON.parse(object)
  } catch (e) {
    return false
  }
  return a ? true : false
}

export const formatTime = 'MMMM Do YYYY, h:mm A'

export const toTanggal = (date, format) => {
  return date ? Moment(date).format(format || formatTime) : '-'
}

export const fromNow = (date, is_pas) => {
  return date ? Moment(date).fromNow(!is_pas) : '-'
}
export const toWeeek = (date) => {
  return date ? Moment(date).week() : '-'
}

export const getFileAsli = (srcImage) => {
  if (srcImage) {
    var startIndex =
      srcImage.indexOf('\\') >= 0 ? srcImage.lastIndexOf('\\') : srcImage.lastIndexOf('/')
    var filename = srcImage.substring(startIndex)
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1)
    }
    // alert(filename);
    if (filename.indexOf('_n') !== -1) {
      const real_image = filename.replace('_n', '')
      const image = srcImage.replace(filename, real_image)
      return image
    }
    return srcImage
  }
  return srcImage
}

// var currency = "$1,123,456.00";
// var number = Number(currency.replace(/[^0-9\.]+/g, ""));
// console.log(number);

export const onCloseHelper = (history, removeAnyObject = []) => {
  // const { history, location } = this.props
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const { method, ...anotherParams } = params
  const _anotherParams = new URLSearchParams(anotherParams)

  if (Array.isArray(removeAnyObject) && removeAnyObject.length > 0) {
    const avaiableObjectArray = Object.keys(anotherParams).filter(
      (item) => !removeAnyObject.includes(item),
    )
    let newParams = {}
    for (let index = 0; index < avaiableObjectArray.length; index++) {
      const key = avaiableObjectArray[index]
      if (Object.hasOwnProperty.call(anotherParams, key)) {
        newParams = {
          ...newParams,
          [key]: anotherParams[key],
        }
      }
    }

    const search_query = new URLSearchParams(newParams)

    return history.push({
      pathname: window.location.pathname,
      search: search_query.toString(),
      query: {
        ...replaceEscapeFromParams(newParams),
      },
      state: {
        only_close: true,
      },
    })
  }
  history.push({
    pathname: window.location.pathname,
    search: _anotherParams.toString(),
    query: {
      ...replaceEscapeFromParams(anotherParams),
    },
    state: {
      only_close: true,
    },
  })
  // console.log(history)
}

export const getIdMitra = (id_reseller = '') => {
  return id_reseller.slice(0, 2)
}

/* 
    item[props.selectorNameSearch] &&
    item[props.selectorNameSearch].toLowerCase().includes(filterText.toLowerCase()


*/

export const GenerateNumberPagging = (appSetting, index) => {
  const searchParams = new URLSearchParams(window.location.search)
  const _params = Object.fromEntries(searchParams.entries())
  const pageOnu = _params?.page || 1
  const perPageOnu = _params?.per_page || appSetting?.data_per_page
  const _numberPages = Number(pageOnu - 1) * Number(perPageOnu)
  const numb = _numberPages + index + 1
  return numb
}

export const uptimeForHuman = (values) => {
  // Unified across Device and InternetGatewayDevice

  let totalSecs = 0
  if (typeof values !== 'undefined') {
    totalSecs = values
  }
  let days = Math.floor(totalSecs / 86400)
  let rem = totalSecs % 86400
  let hrs = Math.floor(rem / 3600)
  if (hrs < 10) {
    hrs = '0' + hrs
  }

  rem = rem % 3600
  let mins = Math.floor(rem / 60)
  if (mins < 10) {
    mins = '0' + mins
  }
  let secs = rem % 60
  if (secs < 10) {
    secs = '0' + secs
  }

  let uptime = days + 'd ' + hrs + ':' + mins + ':' + secs
  return uptime
}

export function displayTime(ticksInSecs) {
  var ticks = ticksInSecs
  let days = Math.floor(ticks / 86400)

  var hh = Math.floor(ticks / 3600)
  var mm = Math.floor((ticks % 3600) / 60)
  var ss = ticks % 60

  return `${days}d` + pad(hh, 2) + ':' + pad(mm, 2) + ':' + pad(ss, 2)
}

function pad(n, width) {
  var n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
}

export const generatorFilterTable = (it, text) => {
  let valid = false

  const searchTextArray = text.split(' ')
  searchTextArray.push(text)

  for (const key in it) {
    if (it.hasOwnProperty(key)) {
      const element = it[key]
      const targetVal = element || ''

      for (let j = 0, textLength = searchTextArray.length; j < textLength; j++) {
        const filterVal = searchTextArray[j].toLowerCase()
        if (targetVal.toString().toLowerCase().indexOf(filterVal) !== -1) {
          valid = true
          break
        }
      }
    }
  }

  if (isDevelopment)
    console.log("return value generatorFilterTable ", valid)

  return valid
}

const typeOf = (o) => Object.prototype.toString.call(o)
const isObject = (o) =>
  o !== null && !Array.isArray(o) && typeOf(o).split(' ')[1].slice(0, -1) === 'Object'

const isPrimitive = (o) => {
  switch (typeof o) {
    case 'object': {
      return false
    }
    case 'function': {
      return false
    }
    default: {
      return true
    }
  }
}

export const ObjToArrayString = (object) => {
  let res = []
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key]
      res.push(`<strong>${key} ke ${element}</strong>`)
    }
  }
  return res
}

export const isNumber = (value) => {
  typeof value === 'number' && isFinite(value)
}

export const ObjectToArray = (object) => {
  let res = []
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key]
      res.push(element)
    }
  }
  return res
}

export const getChanges = (previous, current) => {
  if (isPrimitive(previous) && isPrimitive(current)) {
    if (previous === current) {
      return ''
    }

    return current
  }

  if (isObject(previous) && isObject(current)) {
    const diff = getChanges(Object.entries(previous), Object.entries(current))

    return diff.reduce((merged, [key, value]) => {
      return {
        ...merged,
        [key]: value,
      }
    }, {})
  }

  const changes = []

  if (JSON.stringify(previous) === JSON.stringify(current)) {
    return changes
  }

  for (let i = 0; i < current.length; i++) {
    const item = current[i]

    if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
      changes.push(item)
    }
  }

  return changes
}

export const paginate = (collection, page = 1, numItems = 10) => {
  if (!Array.isArray(collection)) {
    throw new Error(`Expect array and got ${typeof collection}`)
  }
  const currentPage = parseInt(page)
  const perPage = parseInt(numItems)
  const offset = (page - 1) * perPage
  const paginatedItems = collection.slice(offset, offset + perPage)

  return {
    current_page: currentPage,
    per_page: perPage,
    total: collection.length,
    totalPages: Math.ceil(collection.length / perPage),
    data: paginatedItems,
  }
}

export const parseNik = async (_nomorNIK, allValue = null) => {
  if (!_nomorNIK) return
  let nomorNIK = String(_nomorNIK)
  // console.log(nomorNIK)
  nomorNIK = _nomorNIK.replace(/-/gi, '')
  // console.log(nomorNIK)
  const index_of = _nomorNIK.indexOf('_')

  if (nomorNIK.length === 16 && index_of === -1) {
    const wilayah = await getWilayah()

    let tmpat_lahir = wilayah.kabkot[nomorNIK.substring(0, 4)]
    if (tmpat_lahir?.includes('KAB.')) tmpat_lahir = tmpat_lahir?.replace('KAB. ', '')
    if (tmpat_lahir?.includes('KOTA.')) tmpat_lahir = tmpat_lahir?.replace('KOTA ', '')

    let thisYear = new Date().getFullYear().toString().substr(2)
    // let thisCode = nomorNIK.substr(-4);
    const hour = new Date().getHours()
    const minutes = new Date().getMinutes()
    const seconds = new Date().getSeconds()

    // console.log(thisYear)
    // alert(nomorNIK.substr(10, 2))

    let thisDate = {
      hari: nomorNIK.substr(6, 2) > 40 ? nomorNIK.substr(6, 2) - 40 : nomorNIK.substr(6, 2),
      bulan: nomorNIK.substr(8, 2),
      tahun:
        nomorNIK.substr(10, 2) < thisYear
          ? '20' + nomorNIK.substr(10, 2)
          : '19' + nomorNIK.substr(10, 2),
      // tahun: nomorNIK.substr(10, 2) + 2000 > thisYear ? "19" + nomorNIK.substr(10, 2) : "20" + nomorNIK.substr(10, 2)
      // tahun: (Number(nomorNIK.substr(10, 2)) > 1 && Number(nomorNIK.substr(10, 2)) < thisYear) ? "20" + nomorNIK.substr(10, 2) : "19" + nomorNIK.substr(10, 2)
    }

    thisDate.lahir = new Date(
      Number(thisDate.tahun),
      Number(thisDate.bulan) - 1,
      Number(thisDate.hari),
      hour,
      minutes,
      seconds,
    )

    const umur = moment().diff(thisDate.lahir, 'years')
    const jenis_kelamin = nomorNIK.substr(6, 2) > 40 ? 2 : 1

    return {
      nik: _nomorNIK,
      tanggal_lahir: moment(thisDate.lahir).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      agama: 1,
      jenis_kelamin,
      tempat_lahir: tmpat_lahir && tmpat_lahir.toUpperCase(),
      umur,
      pendidikan_kk: umur < 17 ? 1 : null,
      pekerjaan:
        umur < 17
          ? {
            value: '1',
            label: 'BELUM/TIDAK BEKERJA',
          }
          : null,
    }
  }
}

export const terbilangRupiah = function (bilangan) {
  bilangan = String(bilangan)
  // eslint-disable-next-line no-array-constructor
  const angka = new Array(
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
    '0',
  )
  // eslint-disable-next-line no-array-constructor
  const kata = new Array(
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
  ) // eslint-disable-next-line no-array-constructor
  const tingkat = new Array('', 'Ribu', 'Juta', 'Milyar', 'Triliun')

  const panjang_bilangan = bilangan.length
  let kaLimat = ''
  /* pengujian panjang bilangan */
  if (panjang_bilangan > 15) {
    kaLimat = 'Diluar Batas'
    return kaLimat
  }

  /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
  for (let i = 1; i <= panjang_bilangan; i++) {
    angka[i] = bilangan.substr(-i, 1)
  }

  let i = 1
  let j = 0
  kaLimat = ''

  let subkaLimat, kata1, kata2, kata3
  /* mulai proses iterasi terhadap array angka */
  while (i <= panjang_bilangan) {
    subkaLimat = ''
    kata1 = ''
    kata2 = ''
    kata3 = ''

    /* untuk Ratusan */
    if (angka[i + 2] !== '0') {
      if (angka[i + 2] === '1') {
        kata1 = 'Seratus'
      } else {
        kata1 = kata[angka[i + 2]] + ' Ratus'
      }
    }

    /* untuk Puluhan atau Belasan */
    if (angka[i + 1] !== '0') {
      if (angka[i + 1] === '1') {
        if (angka[i] === '0') {
          kata2 = 'Sepuluh'
        } else if (angka[i] === '1') {
          kata2 = 'Sebelas'
        } else {
          kata2 = kata[angka[i]] + ' Belas'
        }
      } else {
        kata2 = kata[angka[i + 1]] + ' Puluh'
      }
    }

    /* untuk Satuan */
    if (angka[i] !== '0') {
      if (angka[i + 1] !== '1') {
        kata3 = kata[angka[i]]
      }
    }

    /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
    if (angka[i] !== '0' || angka[i + 1] !== '0' || angka[i + 2] !== '0') {
      subkaLimat = kata1 + ' ' + kata2 + ' ' + kata3 + ' ' + tingkat[j] + ' '
    }

    /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
    kaLimat = subkaLimat + kaLimat
    i = i + 3
    j = j + 1
  }

  /* mengganti Satu Ribu jadi Seribu jika diperlukan */
  if (angka[5] === '0' && angka[6] === '0') {
    kaLimat = kaLimat.replace('Satu Ribu', 'Seribu')
  }

  return kaLimat + 'Rupiah'
}

export const addDataIntoCache = (selector, content = {}) => {
  const _content = {
    ...content,
    expired: Date.now() + 60000,
  }
  localStorage.setItem(selector, JSON.stringify(_content))
}

export const getDataFromCache = (selector) => {
  const _data = localStorage.getItem(selector)
  return isJSON(_data) && JSON.parse(_data)
}

export const replaceEscapeCharacter = (stringValues) => {
  return stringValues.replace(/[^\w\s]/gi, '')
}

export const replaceEscapeFromParams = (paramsObject) => {
  let new_values = {}
  for (const key in paramsObject) {
    if (Object.hasOwnProperty.call(paramsObject, key)) {
      const element = paramsObject[key]
      new_values = {
        [key]: replaceEscapeCharacter(element),
        ...new_values,
      }
    }
  }

  return new_values
  // return stringValues.replace(/[^\w\s]/gi, '')
}

export const ZeroLead = (numb, length) => {
  /* Untuk kebutuhan leading zero */
  const _num = Number(numb)
  return String(_num).padStart(length, '0')
}

export function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1
  var byteUnits = [' kbps', ' Mbps', ' Gbps', ' Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps']
  do {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
}

export const LastDateMonth = () => {
  var today = new Date();

  // Dapatkan bulan saat ini (0-11, dengan 0 = Januari, 1 = Februari, dst.)
  var currentMonth = today.getMonth();

  // Set objek tanggal ke tanggal pertama bulan ini
  today.setDate(1);

  // Majukan tanggal ke tanggal pertama bulan berikutnya
  today.setMonth(currentMonth + 1);

  today.setDate(today.getDate() - 1);


  var lastDayOfMonth = today.getDate();

  return lastDayOfMonth
}


export const get_mode_access = (auth, url) => {
  const allowed_menu = auth.allowed_menu;
  const mode = allowed_menu?.filter(item => item.to === url).reduce((prev, next) => {
    return {
      ...prev,
      ...next
    }
  }, {});
  return mode
}
