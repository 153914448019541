import swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FakeAxiosFailed, FakeAxiosResponse, FakeAxiosRunning } from 'src/store/actions/axios'

import {
  FakeMultiRequestFailed,
  FakeMultiRequestRun,
  FakeMultiRequestSuccess,
} from 'src/store/actions/multicontent'
import { request } from '../helpers/request'

const MySwal = withReactContent(swal)

// import "./main.scss"

export const HapusItem = ({
  dispatch,
  id,
  url,
  data,
  method = 'post',
  messageError,
  messageTitle,
  title,
  axiosAction,
}) =>
  MySwal.fire({
    title: title || 'Hapus Item  !!!',
    text: messageTitle || 'Apakah anda yakin ingin menghapus item ?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Tidak',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      dispatch(FakeAxiosRunning())
      return request(method, url, data || { id })
        .then((result) =>
          dispatch(
            FakeAxiosResponse({
              data: result.data,
              action: { url, method, action: axiosAction || 'table' },
            }),
          ),
        )
        .catch((err) => {
          let data = null
          // console.log(err)
          if (err.response) {
            data = err.response.data
          }
          // console.log(data)
          dispatch(FakeAxiosFailed())
          swal.showValidationMessage(messageError ? data?.message : 'Data Gagal di Hapus')
        })
    },
    allowOutsideClick: () => !swal.isLoading(),
  }).then((result) => {
    if (result.value) {
      swal.fire('Berhasil!', 'Item Berhasil di Hapus', 'success')
      // update item
    }
  })

export const MessageBox = ({
  dispatch,
  id,
  url,
  method = 'delete',
  title,
  data,
  text,
  html,
  // action = 'table',
  readingResponse,
  selectorRedux,
  input,
  inputLabel,
  inputPlaceholder,
  inputErrorMessage,
  inputField,
  icon,

}) =>
  MySwal.fire({
    title,
    text,
    html,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Tidak',
    showLoaderOnConfirm: true,
    ...(icon && {
      icon
    }),
    preConfirm: (values) => {
      // if (useDispatch)
      dispatch(
        FakeMultiRequestRun(selectorRedux, {
          method,
          url,
          data: {
            ...data,
            ...(id && {
              id,
            }),
            ...(input && {
              [inputField]: values,
            }),
          },
        }),
      )
      /* tujuannya agar setiap data terhapus table ikut update */
      return request(
        method,
        url,
        {
          ...data,
          ...(input && {
            [inputField]: values,
          }),
        } || { id },
      )
        .then((result) =>
          dispatch(
            /* tujuannya agar setiap data terhapus table ikut update */
            FakeMultiRequestSuccess(selectorRedux, {
              method,
              url,
              response: result.data,
            }),
          ),
        )
        .catch((result) => {
          /* tujuannya agar setiap data terhapus table ikut update */
          dispatch(
            FakeMultiRequestFailed(selectorRedux, {
              method,
              url,
              data: {
                ...data,
                ...(id && {
                  id,
                }),
              },
            }),
          )

          if (result && result.response) {
            // console.log(readingResponse)
            if (readingResponse && readingResponse.hasOwnProperty('prefix')) {
              return swal.showValidationMessage(result.response.data[readingResponse.prefix])
            }
            if (readingResponse && readingResponse.hasOwnProperty('message')) {
              return swal.showValidationMessage(readingResponse.message)
            }
          }
          swal.showValidationMessage('Aksi Gagal di lakukan')
        })
    },
    allowOutsideClick: () => !swal.isLoading(),

    ...(input && {
      input,
      inputLabel,
      inputPlaceholder,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve()
          } else {
            resolve(inputErrorMessage)
          }
        })
      },
    }),
  }).then((result) => {
    if (result.value) {
      // console.log(result.value)
      const { response } = result.value
      if (readingResponse && readingResponse.hasOwnProperty('prefix')) {
        return swal.fire('Berhasil', response[readingResponse.prefix], 'success')
      }
      if (readingResponse && readingResponse.hasOwnProperty('message')) {
        return swal.fire('Berhasil', readingResponse.message, 'success')
      }

      swal.fire('Berhasil!', 'Aksi berhasil', 'success')
      // update item
    }
  })

export const BuatTemplateSuratSelector = async () => {
  const { value: fruit } = await MySwal.fire({
    title: '<h4>Apakah sekaligus akan di jadikan template pekerjaan ??</h4>',
    // input: 'radio',
    input: 'text',
    inputLabel: 'Nama Template Pekerjaan',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Tidak',
    allowOutsideClick: false,
    allowEscapeKey: false,
    inputValidator: (value) => {
      if (!value) {
        return 'Anda belum menuliskan nama template'
      }
    },
    focusConfirm: false,
    preConfirm: (value) => {
      return new Promise((resolve) => {
        if (value) {
          resolve()
        } else {
          resolve('Kamu belum menuliskan nama template..')
        }
      })
    },
    footer: `<ul>
            <li>
            Klik Ya (Untuk menyimpannya sebagai template) <br /> 
            dan menjadikannya template sekaligus !!
            </li>
            <li>
            Klik Tidak (Untuk menyimpannya sebagai penyimpan data pekerjaan)
            </li>
        </ul>
        `,
  })

  if (fruit) {
    // swal.fire(`You selected: ${fruit}`)
    return fruit
  }
}

export const DefaultPasswordIdPelanggan = async () => {
  const { value: fruit } = await MySwal.fire({
    title: '<h4>Default Kata sandi data pelanggan butuh di isi oleh bapak :) </h4>',
    input: 'text',
    inputLabel: 'Kata sandi default pelanggan',
    inputPlaceholder: 'Kata sandi default pelanggan',
    // showCancelButton: true,
    confirmButtonText: 'Simpan',
    // cancelButtonText: 'Tidak',
    allowOutsideClick: false,
    allowEscapeKey: false,
    inputValidator: (value) => {
      if (!value) {
        return 'Anda belum mengisi default kata sandi'
      }
    },
    focusConfirm: false,
    preConfirm: (value) => {
      return new Promise((resolve) => {
        if (value) {
          resolve()
        } else {
          resolve('Anda belum mengisi default kata sandi')
        }
      })
    },
    footer: `Secara default username pelanggan yang nanti nya di gunakan untuk login adalah id_pelanggan`,
  })

  if (fruit) {
    // swal.fire(`You selected: ${fruit}`)
    return fruit
  }
}

export const CetakStrukValidator = async () => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: 'Cetak Struk Pembayaran',
    icon: 'question',
    text: 'Klik Ya Untuk Cetak Struk hanya menggunakan text, dan Klik Tidak untuk cetak struk berdasarkan Invoice !',
    inputPlaceholder: 'Pilih Status Penduduk',
    showCancelButton: true,
    confirmButtonText: 'Ya (text only)',
    denyButtonText: `Invoice (Invoice)`,
    cancelButtonText: 'Tidak',
    showCloseButton: true,
    showDenyButton: true,
  })
  // console.log(res)
  // const { isConfirmed } = res;
  return {
    text_only: isConfirmed,
    invoice: isDenied,
    dismiss: isDismissed,
  }
}

export const MessageValidator = async ({
  title = 'Judul',
  text = 'Sub Judul',
  confirmButtonText = 'Ya',
  cancelButtonText = "Tidak",
}) => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title,
    icon: 'question',
    text,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText: cancelButtonText,
    showCloseButton: true,
  })
  // console.log(res)
  // const { isConfirmed } = res;
  return {
    isConfirmed,
    isDenied,
    isDismissed,
  }
}

export const ErrorKategory = async (text, is_show_confirm) => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: 'Ticket Kategory Masih Kosong',
    icon: 'error',
    text:
      text ||
      'Silahkan atur ticket kategory terlebih dahulu, sebelum anda melanjutkan, klik ya untuk mengatur ticket kategory dan klik tidak untuk kembali',
    // inputPlaceholder: 'Pilih Status Penduduk',
    // showCancelButton: true,
    confirmButtonText: 'Ya',
    denyButtonText: `Tidak`,

    showCloseButton: true,
    showDenyButton: true,
    showConfirmButton: !is_show_confirm,
  })
  // console.log(res)
  // const { isConfirmed } = res;
  return {
    isConfirmed,
    isDenied,
    isDismissed,
  }
}

export const BayarUangPass = async () => {
  const { isConfirmed, isDismissed } = await MySwal.fire({
    title: 'Pembayaran Tagihan',
    icon: 'question',
    text: 'Klik ya untuk memastikan bahwa pelanggan membayar tagihan dengan uang pass',
    inputPlaceholder: 'Pilih Status Penduduk',
    showCancelButton: true,
    confirmButtonText: 'Ya (Uang Pas)',
    cancelButtonText: 'Tidak',
    showCloseButton: true,
  })
  // console.log(res)
  // const { isConfirmed } = res;
  return {
    uang_pass: isConfirmed,
    // invoice: isDenied,
    dismiss: isDismissed,
  }
}

export const GajiValidatorFailed = async () => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: 'Gaji Pokok Berubah',
    icon: 'question',
    text: 'Gaji pokok pekerja tersebut mengalami perubahan, klik ya, agar gaji pokoknya berubah, atau klik tidak, dan gaji pokok tetap menggunakan gaji sebelumnya',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    denyButtonText: `Tidak`,
    cancelButtonText: 'Teliti Kembali',
    showCloseButton: true,
    showDenyButton: true,
  })

  return {
    next: isConfirmed,
    no: isDenied,
    teliti_kembali: isDismissed,
  }
}

export const ErrorMessage = async (message) => {
  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    text:
      message || 'Seperti yang anda ketahui, kami pun dapat mengalami kendala yang tidak terduga',
    // showCloseButton: true,
  })
}

export const PelangganInstansi = async () => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: 'Pelanggan atau Instansi !!!',
    icon: 'question',
    text: 'Apakah anda akan menambahkan pelanggan berdasarkan Instansi ? ',
    inputPlaceholder: 'Pilih Status Penduduk',
    showCancelButton: true,
    confirmButtonText: 'Ya (Instansi)',
    denyButtonText: `Bukan (Bukan)`,
    cancelButtonText: 'Kembali',
    showCloseButton: true,
    showDenyButton: true,
  })
  // console.log(res)
  // const { isConfirmed } = res;
  return {
    instansi: isConfirmed,
    pelanggan: isDenied,
    dismiss: isDismissed,
  }
}

export const TambahPelangganValidator = async (action) => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: 'Gratis Satu Bulan Pertama? ',
    icon: 'question',
    text: 'Klik Ya Untuk pelanggan gratis satu bulan pertama ..',
    inputPlaceholder: 'Pilih Status Penduduk',
    showCancelButton: true,
    showDenyButton: true,
    denyButtonText: 'Tidak',
    confirmButtonText: 'Ya (gratis)',
    cancelButtonText: 'Kembali',
    showCloseButton: false,
  })
  return { isConfirmed, isDenied, isDismissed }
}

export const EditPelangganValidator = async (html, title) => {
  const { isConfirmed, isDenied, isDismissed } = await MySwal.fire({
    title: title || 'Perubahan data pelanggan',
    icon: 'question',
    html: html,
    // showDenyButton: true,
    // denyButtonText: 'Tidak',
    confirmButtonText: 'Ya',
    showCloseButton: false,
    cancelButtonText: 'Kembali',
    showCancelButton: true,
  })
  return { isConfirmed, isDenied, isDismissed }
}

export const HapusAndResult = ({ method = 'delete', url = '/tagihan/laporan', dataPost }) => {
  const swalWithBootstrapButtons = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  })

  return swalWithBootstrapButtons
    .fire({
      title: 'Hapus Data?',
      text: 'Menghapus data yang anda pilih ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      allowOutsideClick: () => !MySwal.isLoading(),
    })
    .then((result) => {
      if (result.isConfirmed) {
        const data = request(method, url, {
          ...dataPost,
        })
          .then((response) => {
            console.log(response)
            // if (!response.ok) {
            //   return swalWithBootstrapButtons.fire(
            //     'Cancelled',
            //     'Your imaginary file is safe :)',
            //     'error',
            //   )
            // }
            swalWithBootstrapButtons.fire('Berhasil!', 'File anda berhasil di hapus', 'success')
            return response
          })
          .catch((err) => {
            swalWithBootstrapButtons.fire(
              'Gagal',
              'Data anda aman, hanya gagal di hapus :)',
              'error',
            )
          })
        return data
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === MySwal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire('Cancelled', 'Your imaginary file is safe :)', 'error')
      }
    })
};


/* MessageBoxEx adalah commandline yang menggunakan return value,  */
export const MessageBoxEx = ({
  id,
  url,
  method = 'delete',
  title,
  data,
  text,
  html,
  readingResponse,
  input,
  inputLabel,
  inputPlaceholder,
  inputErrorMessage,
  inputField,
  icon,
}) => {
  return new Promise((resolve, reject) => {
    MySwal.fire({
      title,
      text,
      html,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      showLoaderOnConfirm: true,
      html,
      ...(icon && {
        icon
      }),
      preConfirm: (values) => {
        // if (useDispatch)
        // dispatch(
        //   FakeMultiRequestRun(selectorRedux, {
        //     method,
        //     url,
        //     data: {
        //       ...data,
        //       ...(id && {
        //         id,
        //       }),
        //       ...(input && {
        //         [inputField]: values,
        //       }),
        //     },
        //   }),
        // )
        /* tujuannya agar setiap data terhapus table ikut update */
        return request(
          method,
          url,
          {
            ...data,
            ...(input && {
              [inputField]: values,
            }),
          } || { id },
        )
          .then((result) => {
            return result?.data
            // resolve(result)
          })
          .catch((result) => {
            console.log(result)
            /* tujuannya agar setiap data terhapus table ikut update */
            if (result && result.response) {
              // console.log(readingResponse)
              if (readingResponse && readingResponse.hasOwnProperty('prefix')) {
                return swal.showValidationMessage(result.response.data[readingResponse.prefix])
              }
              if (readingResponse && readingResponse.hasOwnProperty('message')) {
                return swal.showValidationMessage(readingResponse.message)
              }
            }
            swal.showValidationMessage('Aksi Gagal di lakukan')
          })
      },
      allowOutsideClick: () => !swal.isLoading(),

      ...(input && {
        input,
        inputLabel,
        inputPlaceholder,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve()
            } else {
              resolve(inputErrorMessage)
            }
          })
        },
      }),
    }).then((result) => {
      // console.log(result)
      if (result.value) {
        console.log(result.value)
        const response = result.value
        if (readingResponse && readingResponse.hasOwnProperty('prefix')) {
          return swal.fire('Berhasil', response[readingResponse.prefix], 'success')
        }
        if (readingResponse && readingResponse.hasOwnProperty('message')) {
          return swal.fire('Berhasil', readingResponse.message, 'success')
        }

        swal.fire('Berhasil!', 'Aksi berhasil', 'success')
        // update item
      }
      resolve(result)
    })

  })
}
