// reducer.js
import { handleActions } from 'redux-actions';
import { ActionTypes } from '../constants';

const initialState = {
    hiddenFieldValue: {},
};

const TOGGLE_HIDDEN_FIELD = ActionTypes?.TOGGLE_HIDDEN_FIELD

export default {
    dasboard_field: handleActions(
        {
            [TOGGLE_HIDDEN_FIELD]: (state, { payload: fieldName }) => ({
                ...state,
                hiddenFieldValue: {
                    ...state.hiddenFieldValue,
                    [fieldName]: {
                        hidden: !state.hiddenFieldValue[fieldName]?.hidden,
                    },
                },
            }),
        },
        initialState
    )
}


