import { all, fork } from 'redux-saga/effects';
import authentication from './auth';
import register from './register';
import axios from './axios';
import multicontent from './multicontent';
import ambilPengaturan from './ambilPengaturan';
import ambilProfile from './ambilProfile';
import ParsialRequest from './parsialrequest';
// import { take, call, race } from 'redux-saga/effects'
// import fetchUsers from './path/to/fetchUsers'

// function* fetchUsersSaga() {
//     const { response, cancel } = yield race({
//         response: call(fetchUsers),
//         cancel: take(CANCEL_FETCH)
//     })
// }



// import
/**
 * rootSaga
 */
export default function* root() {
    yield all([
        fork(authentication),
        fork(register),
        fork(axios),
        fork(multicontent),
        fork(ambilPengaturan),
        fork(ambilProfile),
        fork(ParsialRequest),
    ]);
}



