import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { ActionTypes } from '../constants';


export const userState = {
    status: 'idle',
    response: '',
    redirect: '',
};

export default {
    user_register: handleActions(
        {

            [ActionTypes.USER_REGISTER]: state => immutable(state, {
                status: { $set: 'running' },
            }),
            [ActionTypes.USER_REGISTER_FAILED]: (state, { redirect, payload }) => immutable(state, {
                isAuthenticated: { $set: false },
                status: { $set: 'error' },
                response: { $set: payload },
                redirect: { $set: redirect },
            }),

            [ActionTypes.USER_REGISTER_SUCCESS]: (state, { payload }) => immutable(state, {
                isAuthenticated: { $set: true },
                status: { $set: 'success' },
                response: { $set: payload },
                redirect: { $set: null },
            }),

        },
        userState
    ),
};
