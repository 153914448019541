/**
 * @module Sagas/MultiContent
 * @desc MultiContent
 */


import { all, call, put, takeEvery, select } from 'redux-saga/effects'
import { request } from 'src/helpers/request'
import { ActionTypes } from '../constants'
// import { race } from 'core-js/fn/promise'
// import { ActionTypes } from "constants/index";
// import { request } from "modules/helpers";
/* 
  how to use
  url: 
  method: 
  data: 
*/

export function* Excute({ reduxUid, payload }) {
  // console.log(payload)
  const state = yield select()
  // console.log(state)
  const { authentication } = state;

  if (authentication?.status === 'running') return;

  const dataPost = payload.dataPost
  //   console.log(state)

  const targeturl =
    payload.payload && payload.payload !== undefined
      ? `${payload.url}?&target=${payload.payload}`
      : payload.url

  const abortController = typeof 'AbortController' !== undefined && new AbortController()

  // console.log(payload)

  yield put({
    type: ActionTypes.REQUEST_RUN_START,
    lastaction: {
      url: payload.url,
      method: payload.method,
      dataPost,
    },
    reduxUid,
  })


  // console.log(payload)

  try {
    const res = yield call(request, payload.method, targeturl, dataPost, null, abortController)

    const { data } = res

    yield put({
      type: ActionTypes.REQUEST_RUN_SUCCESS,
      response: data,
      lastaction: {
        url: payload.url,
        method: payload.method,
      },
      reduxUid,
    })
  } catch (err) {
    const ret = err && err.response

    if (ret?.status === 401) {
      return yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
        redirect: window.location.pathname,
      })
    }

    yield put({
      type: ActionTypes.REQUEST_RUN_FAILURE,
      response: err.response !== undefined ? err.response : 'error',
      reduxUid,
      lastaction: {
        url: payload.url,
        method: payload.method,
      },
    })
  } finally {
    if (abortController) {
      abortController.abort() // Tell the browser to abort request
    }
  }
}
/**
 * MultiContent Sagas
 */
// export default function* root() {

//   while (true) {
//     yield takeEvery(ActionTypes.REQUEST_RUN)
//     yield race({
//       task: call(Excute),
//       cancel: take('CANCEL_TASK')
//     })
//   }
// }


export default function* root() {
  yield all([
    takeEvery(ActionTypes.REQUEST_RUN, (Excute))
  ])
  //
}

