import React, { useEffect, useState } from 'react';
import { getParams } from 'src/helpers/column/Pelanggan/Form/Pelanggan';
import Panel from '../tagihan/Panel';
import { request } from 'src/helpers/request';
import 'src/components/PrintPreview/PrintStyle.scss';

const Invoice = (props) => {
    const [data, setData] = useState()
    const [isLoading, setLoading] = useState()
    const [errorMsg, setError] = useState()
    let invoice_tidak_tersedia = true
    const [invoicetersedia, setInvoiceTersedia] = useState(false)
    const params = getParams()

    useEffect(() => {
        let isSubscribed = true
        setLoading(true)
        async function getDefaultForm() {
            try {
                const res = await request(
                    'get',
                    `/invoice?q=${params?.q}`,
                )
                if (isSubscribed) {
                    setLoading(false)
                    setInvoiceTersedia(true)
                    setData(res?.data?.data)
                    setError(false)
                    setTimeout(function () {
                        window.print();
                    }, 1000)

                }
            } catch (error) {
                setError(error?.response)
                setLoading(false)
            }
        }
        getDefaultForm(isSubscribed)
        return () => (isSubscribed = false)
    }, [params?.q])




    invoice_tidak_tersedia = errorMsg?.data?.message === "Invoice tidak tersedia"

    return (
        <div>
            <div className='p-2 d-print-none'>
                <div className='btn-group d-print-none' role='group'>
                    <button className='btn btn-sm btn-primary' disabled>
                        Save PDF
                    </button>

                    <button className='btn btn-sm btn-success' onClick={() => window.print()}>
                        Print
                    </button>
                </div>
            </div>

            <div id='print_target'>
                {isLoading && <center><p>Silahkan tunggu ...</p></center>}
                <div className={invoicetersedia ? 'pagez page-breaks A4 portrait margin-narrow  with_logo' : ""}>
                    {invoicetersedia && (
                        <Panel data={data} />
                    )}
                </div>
                {!invoicetersedia && <center><p>Invoice tidak tersedia</p></center>}
            </div>
        </div>
    )
}

export default Invoice