import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { ActionTypes } from '../constants';
// import { ActionTypes } from 'constants/index';

export const userState = {
    status: 'idle',
    response: Object.assign({}),
};
export default {
    app_setting: handleActions(
        {
            [ActionTypes.RESET_SETING_STATE]: state => immutable(state, {
                status: { $set: 'idle' },
                response: { $set: {} },
            }),
            [ActionTypes.SETTING_STATE]: state => immutable(state, {
                status: { $set: 'running' },
            }),
            [ActionTypes.SETTING_STATE_SUCCESS]: (state, { response }) => immutable(state, {
                status: { $set: 'success' },
                response: { $set: response },
            }),
            [ActionTypes.SETTING_STATE_UPDATESETTING]: (state, { response, ...test }) => {
                console.log(state)
                console.log(test)
                console.log(response)
                return immutable(state, {
                    status: { $set: 'success' },
                    response: { $set: response },
                })
            },
            [ActionTypes.SETTING_STATE_SETPROFILE]: (state, { response }) => immutable(state, {
                status: { $set: 'profile_success' },
                response: { $set: response },
            }),
            [ActionTypes.SETTING_STATE_FAILED]: (state, { response }) => {
                const ret_401 = response && response.statusText || null;
                return immutable(state, {
                    status: { $set: 'error' },
                    response: { $set: ret_401 || state.response }
                })
            }
        },
        userState
    ),
};
