
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()
/** Singleton toaster instance. Create separate instances for different options. */


export const ErrorToast = (message, position) => toast(message, {
    position: position || "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "error",
    theme: 'colored'
})

export const SuccesToast = (message, position) => toast(message, {
    position: position || "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    theme: 'colored'
});
