import SidebarReducer from './sidebar'
import auth from './auth';
import register from './register';
import profile from './profile';
import axios from './axios';
import multiContent from './multicontent';
import ParsialRequest from './parsialRequest';
import app_setting from './appSetting';
import dasboard_field from './hiddenField';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...auth,
    ...register,
    ...profile,
    ...axios,
    ...app_setting,
    ...dasboard_field,
    axiosTemp: multiContent,
    parsial: ParsialRequest,
    set: SidebarReducer
};
