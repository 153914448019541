// @flow
/**
 * @module Actions/AxiosState
 * @desc User Actions
 */
// import { ActionTypes } from 'constants/index';
/* eslint-disable */

import { ActionTypes } from "../constants";

export const MakeRequest = (
    method,
    url,
    postdata,
    action = 'notable',
    responseType
) => ({
    type: ActionTypes.AXIOS_STATE,
    method,
    url,
    postdata,
    action,
    responseType
});

export const AxiosProfile = payload => ({
    type: ActionTypes.AXIOS_STATE_PROFILE,
    response: payload,
});


export const FakeAxiosResponse = ({ data, action }) => ({
    type: ActionTypes.AXIOS_STATE_SUCCESS,
    response: data,
    action,
});

export const FakeAxiosFailed = () => ({
    type: ActionTypes.AXIOS_STATE_FAILURE,
    action: {
        action: 'delete'
    }
});

export const FakeAxiosRunning = () => ({
    type: ActionTypes.AXIOS_FAKE_RUNNING,
});
