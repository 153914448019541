import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { ActionTypes } from "../constants";
// import { ActionTypes } from "constants/index";

export const userState = {
    status: "idle",
    response: {},
    lastaction: {
        url: "",
        method: "",
        postdata: Object.assign({})
    }
};
export default {
    axios: handleActions(
        {
            [ActionTypes.AXIOS_STATE]: (state, { action }) => {
                // console.log(state)
                // console.log(action)
                return immutable(state, {
                    status: { $set: "running" },
                    lastaction: { $set: { action } }
                })
            },



            [ActionTypes.AXIOS_FAKE_RUNNING]: (state, { action }) =>
                immutable(state, {
                    status: { $set: "running" },
                    lastaction: { $set: { action } }
                }),

            [ActionTypes.AXIOS_STATE_SUCCESS]: (prevState, { action, response }) => {
                // console.log(response);

                if (
                    action.url === "foto/setprofile" &&
                    action.postdata.action === "foto_profile"
                ) {
                    return immutable(prevState, {
                        status: { $set: "success" },
                        response: { $set: prevState.response },
                        lastaction: { $set: action }
                    });
                }
                return immutable(prevState, {
                    status: { $set: "success" },
                    response: { $set: response },
                    lastaction: { $set: action },
                });
            },

            [ActionTypes.AXIOS_STATE_FAILURE]: (state, { response, action }) =>
                immutable(state, {
                    status: { $set: "error" },
                    response: { $set: response },
                    lastaction: { $set: action }
                }),

            [ActionTypes.AXIOS_STATE_PROFILE]: (state, { response }) =>
                immutable(state, {
                    status: { $set: "profile_set" },
                    response: { $set: response }
                })
        },
        userState
    )
};
