import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { ActionTypes } from '../constants';
// import { ActionTypes } from 'constants/index';

export const userState = {
    status: 'idle',
    response: Object.assign({}),
    lastaction: Object.assign({}),
};
export default {

    Profile: handleActions(
        {
            // reset profile to default if user loggout
            [ActionTypes.PROFILE_SET_RESET]: state => immutable(state, {
                status: { $set: 'error' },
                response: { $set: Object.assign({}) },
            }),

            [ActionTypes.PROFILE_SET]: state => immutable(state, {
                status: { $set: 'running' },
            }),

            [ActionTypes.PROFILE_UPDATE]: state => immutable(state, {
                status: { $set: 'running' },
            }),

            [ActionTypes.PROFILE_UPDATE_SUCCESS]: (state, { payload }) => immutable(state, {
                status: { $set: 'success' },
                response: { $set: payload },
            }),
            [ActionTypes.PROFILE_UPDATE_FAILED]: (state, { payload }) => immutable(state, {
                status: { $set: 'error' },
                response: { $set: payload },
            }),

            [ActionTypes.PROFILE_SET_SUCCESS]: (state, { payload, lastaction }) => immutable(state, {
                status: { $set: 'success' },
                response: { $set: payload },
                lastaction: { $set: lastaction },
            }),

            [ActionTypes.PROFILE_SET_FAILED]: (state, { payload, lastaction }) => {
                // console.log(state)
                return immutable(state, {
                    status: { $set: 'error' },
                    response: {
                        $set: {
                            ...state.response, /* Masukan Previus */
                            ...payload
                        }
                    },
                    lastaction: { $set: lastaction },
                })
            },

            [ActionTypes.PROFILE_SET_FOTO]: (state, { response }) => immutable(state, {
                status: { $set: 'setprofile_success' },
                response: { $set: response },
            }),

            [ActionTypes.PROFILE_UPLOAD_IMAGE]: state => immutable(state, {
                status: { $set: 'running' },
            }),

            [ActionTypes.PROFILE_UPLOAD_IMAGE_SUCCESS]: (state, { payload, lastaction }) => immutable(state, {
                status: { $set: 'success' },
                response: { $set: payload },
                lastaction: { $set: lastaction },
            }),

            [ActionTypes.PROFILE_UPLOAD_IMAGE_FAILED]: (state, { payload }) => immutable(state, {
                status: { $set: 'error' },
                response: { $set: payload },
            }),
        },
        userState
    ),

};
