import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'

export const Helmet = Loadable({
  loader: () => import('react-helmet'),
  loading: () => null,
})

class RoutePrivate extends React.Component {
  render() {
    const {
      component: Component,
      isAuthenticated,
      contentMenu,
      to,
      appSetting,
      ...rest
    } = this.props

    // console.log(contentMenu?.label)
    // console.log(rest)


    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <React.Fragment>
              <Helmet
                defer={false}
                htmlAttributes={{ lang: 'en' }}
                encodeSpecialCharacters
                defaultTitle={`PT. Sarana Kawan Setia - BILLING SYSTEM`}
                // titleTemplate={`%s | test app`}
                titleAttributes={{ itemprop: 'name', lang: 'en' }}
              />
              <Component avaiableMenu={appSetting?.menus} appSetting={appSetting} {...props} />
            </React.Fragment>
          ) : (
            <div>
              <Redirect
                to={{
                  pathname: to,
                  state: { redirect: props.location.pathname, isAuthenticated },
                }}
              />
            </div>
          )
        }}
      />
    )
  }
}

RoutePrivate.propTypes = {
  // component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object,
  to: PropTypes.string,
}

RoutePrivate.defaultProps = {
  to: '/login',
}

export default RoutePrivate
