import React from 'react'
import { SocketContext } from 'src/module/socket'
import { SOCK_POINT } from 'src/module/config'
import socketIOClient from 'socket.io-client'
import { GetToken } from 'src/helpers/request'

const token = GetToken()

const WhatsAppProvider = ({ children }) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const myState = {
    socket: socketIOClient(SOCK_POINT, {
      autoConnect: false,
      auth: {
        ...(token && {
          Authorization: 'Bearer ' + token?.token,
        }),

        MyIdReseller: params?.id_reseller,
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            ...(token && {
              Authorization: 'Bearer ' + token?.token,
            }),
            // MyIdReseller: 'Test=ID=Reseller',
          },
        },
      },
    }),
  }
  return <SocketContext.Provider value={myState}>{children}</SocketContext.Provider>
}
export default WhatsAppProvider
