import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ZeroLead } from '../module/helpers'

class RoutePublic extends Component {
  redirectComponent = (props, to) => {
    return <Redirect to={props.redirect !== undefined ? props.redirect : to} />
  }

  render() {
    const { component: Component, isAuthenticated, to, appSetting, profile, ...rest } = this.props

    return (
      <React.Fragment>
        <Route
          render={(props) =>
            isAuthenticated ? this.redirectComponent(props, to) : <Component {...props} />
          }
          {...rest}
        />
      </React.Fragment>
    )
  }
}
// RoutePublic.propTypes = {
const tahun = new Date().getFullYear()
const bulan = new Date().getMonth()
const _bulan = ZeroLead(bulan + 1, 2)

RoutePublic.defaultProps = {
  // to: `/dashboard?tahun=${tahun}&bulan=${_bulan}`,
  to: `/redirect`,
}

export default RoutePublic
