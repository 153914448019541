import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { ActionTypes } from '../constants';


export const userState = {
    isAuthenticated: false,
    status: 'idle',
    response: '',
    redirect: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    authentication: handleActions(
        {
            [REHYDRATE]: (state, { payload }) => immutable(state, {
                redirect: { $set: null },
                isAuthenticated: {
                    $set:
                        payload && payload.authentication !== undefined
                            ? payload.authentication.isAuthenticated
                            : false
                },
            }),

            [ActionTypes.USER_AUTH]: state => immutable(state, {
                status: { $set: 'running' },
            }),
            [ActionTypes.USER_AUTH_FAILURE]: (state, { redirect, payload }) => immutable(state, {
                isAuthenticated: { $set: false },
                status: { $set: 'error' },
                response: { $set: payload },
                redirect: { $set: redirect },
            }),

            [ActionTypes.USER_LOGIN_SUCCESS]: (state, { payload }) => immutable(state, {
                isAuthenticated: { $set: true },
                status: { $set: 'success' },
                response: { $set: payload },
                redirect: { $set: null },
            }),

            [ActionTypes.USER_LOGOUT]: state => immutable(state, {
                status: { $set: 'running' },
            }),

            [ActionTypes.USER_LOGOUT_SUCCESS]: state => immutable(state, {
                isAuthenticated: { $set: false },
                status: { $set: 'idle' },
                redirect: { $set: '/login' },
            }),
            [ActionTypes.USER_LOGOUT_FAILURE]: state => immutable(state, {
                isAuthenticated: { $set: true },
                status: { $set: 'idle' },
                // redirect: { $set: '/login' },
            }),
        },
        userState
    ),
};
