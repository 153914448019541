/**
 * @module Sagas/MultiContent
 * @desc MultiContent
 */


import { all, call, put, takeEvery } from 'redux-saga/effects'
import { request } from 'src/helpers/request'
import { ActionTypes } from '../constants'
import { ENDPOINT } from '../../module/config'

// import { ActionTypes } from "constants/index";
// import { request } from "modules/helpers";
/* 
  how to use
  url: 
  method: 
  data: 
*/

const getFullUrl = (url_params) => {
    const url = `${ENDPOINT}${url_params}`
    return new URL(url)
}

export function* Excute({ reduxUid, payload }) {
    const dataPost = payload.dataPost
    const abortController = typeof 'AbortController' !== undefined && new AbortController()

    const url = getFullUrl(payload.url)
    const urlSearchParams = new URLSearchParams(url.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const query = new URLSearchParams({
        ...params,
        is_details: true
    })

    const f_url = payload.url;
    const next_url = `${url.pathname}?${query.toString()}`

    yield put({
        type: ActionTypes.PARSIAL_RUN_START,
        lastaction: {
            url: payload.url,
            method: payload.method,
            dataPost,
        },
        reduxUid,
    })

    // console.log(payload)

    try {
        const res = yield call(request, payload.method, f_url, dataPost, null, abortController)
        const { data } = res


        yield put({
            type: ActionTypes.PARSIAL_RUN_PREPARE,
            response: data,
            lastaction: {
                url: payload.url,
                method: payload.method,
            },
            reduxUid,
        })


        /* tambahkan kedua nya */
        /* hanya berlaku untuk get_data */
        if (payload?.method === 'get') {
            const next = yield call(request, payload.method, next_url, dataPost, null, abortController)
            const _data_next = next?.data

            yield put({
                type: ActionTypes.PARSIAL_RUN_SUCCESS,
                response: _data_next,
                lastaction: {
                    url: payload.url,
                    method: payload.method,
                },
                reduxUid,
            })

        }



    } catch (err) {
        const ret = err && err.response

        if (ret?.status === 401) {
            return yield put({
                type: ActionTypes.USER_AUTH_FAILURE,
                redirect: window.location.pathname,
            })
        }

        yield put({
            type: ActionTypes.PARSIAL_RUN_FAILURE,
            response: err.response !== undefined ? err.response : 'error',
            reduxUid,
            lastaction: {
                url: payload.url,
                method: payload.method,
            },
        })
    } finally {
        if (abortController) {
            abortController.abort() // Tell the browser to abort request
        }
    }
}
/**
 * MultiContent Sagas
 */
export default function* root() {
    yield all([
        takeEvery(ActionTypes.PARSIAL_RUN, Excute)
    ])
    //
}
