// @flow
/**
 * @module Actions/SettingUser
 * @desc User Actions
 */

import { ActionTypes } from "../constants";

/* eslint-disable */
export const GetSetting = () => ({
    type: ActionTypes.SETTING_STATE,
});

export const UpdateSetting = (response) => ({
    type: ActionTypes.SETTING_STATE_UPDATESETTING,
    response
});
