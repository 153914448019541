import React from 'react'
import { useDispatch } from 'react-redux'
import { toTanggal } from 'src/module/helpers'
import { TambahPelangganValidator } from 'src/components/Hapus'
import { MultiRequest } from 'src/store/actions/multicontent'

const Instansi = React.lazy(() => import(/* webpackPrefetch: true */ './Instansi'))
const TambahPelanggan = React.lazy(() => import(/* webpackPrefetch: true */ './TambahPelanggan'))


export const getParams = (searchParams, not_null) => {
  const urlSearchParams = new URLSearchParams(not_null ? searchParams : window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  return params;
}

const FormTambahPelanggan = ({ location, params, ...props }) => {
  const dispatch = useDispatch()


  const onSubmit = async (initialValues) => {
    const { id_reseller, id_mitra } = props
    const params = getParams();
    const is_writeable_gratis = params?.method === "new"

    const ret = is_writeable_gratis ? await TambahPelangganValidator() : {}
    const { isConfirmed, /* isDenied */ isDismissed } = ret

    if (isDismissed && is_writeable_gratis) return

    const gratis_satu_bulan_pertama = is_writeable_gratis ? isConfirmed : initialValues?.gratis_satu_bulan_pertama

    const _nik = initialValues?.nik?.replace(/-/gi, '')
    dispatch(
      MultiRequest('pelanggan', {
        method: 'post',
        url: params?.is_instansi
          ? '/administrasi/tambah_pelanggan_instansi'
          : '/administrasi/tambah_pelanggan',
        dataPost: {
          data: {
            id_mitra,
            id_reseller,
            ...initialValues,
            ...(initialValues.tanggal_registrasi && {
              tanggal_registrasi: toTanggal(
                initialValues.tanggal_registrasi,
                'YYYY-MM-DD HH:mm:ss',
              ),
            }),
            nik: _nik,
            gratis_satu_bulan_pertama,
          },
          method: params?.method === 'new' ? 'tambah_pelanggan_instansi' : params?.method,
          actions: 'to_table',
        },
      }),
    )
  }

  return params?.is_instansi ? (
    <Instansi
      title={params?.method === 'new' ? 'Tambah Pelanggan Instansi' : 'Ubah Pelanggan Instansi'}
      location={location}
      {...props}
      onSubmit={onSubmit}
      selectorRedux="pelanggan"
      params={params}
 
    />
  ) : (
    <TambahPelanggan location={location} params={params} {...props} />
  )
}
export default FormTambahPelanggan
