import React, { useEffect, useState } from 'react';
import "./progress.css"
import { useHistory } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
// import axios from 'axios';
import { request } from 'src/helpers/request';
import { ZeroLead } from 'src/module/helpers';

const Redirector = (props) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const history = useHistory();

    const tahun = new Date().getFullYear()
    const bulan = new Date().getMonth()
    const _bulan = ZeroLead(bulan + 1, 2)


    const fetchRedirectUrl = async (e) => {
        // e.preventDefault();
        setLoading(true);
        setProgress(30);
        try {
            const req = await request('get', "/app/setting")

            const appSetting = req.data
            const _query = new URLSearchParams({
                tahun,
                bulan: _bulan,
                ...(appSetting?.default_tagihan && {
                    filter: appSetting?.default_tagihan
                })
            })

            setProgress(100);
            setLoading(false);
            history.push({
                pathname: "/dashboard",
                search: _query.toString(),
                query: {
                    ..._query,
                },
            })

            // history.push('/dashboard'); // Redirect to dashboard
        } catch (err) {
            setError('Login failed. Please check your credentials.');
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchRedirectUrl();
    }, [history]);


    return (
        <React.Fragment>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
                {loading && (
                    <div className="loading-spinner d-flex justify-content-center align-items-center">
                        <CSpinner size="lg" />
                    </div>
                )}
            </div>
        </React.Fragment>

    )
}

export default Redirector