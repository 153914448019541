import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import { request } from 'src/helpers/request';



/**
 * Login
 */
// const urlApi = 'http://localhost:3333/api/';

export function* register({ payload }) {
    try {
        const response = yield call(request, 'post', `/auth/register`, payload);
        const { data: { data } } = response;

        yield put({
            type: ActionTypes.USER_REGISTER_SUCCESS,
            payload: data
        });


    }
    catch (err) {
        /* istanbul ignore next */

        const message = err.response !== undefined ? err.response.data : { message: 'Terjadi galat pada server' };

        yield put({
            type: ActionTypes.USER_REGISTER_FAILED,
            payload: message,
        });


    }
}


export default function* root() {
    yield all([
        takeLatest(ActionTypes.USER_REGISTER, register),
    ]);
}
