import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ActionTypes } from '../constants/index'
import { request } from 'src/helpers/request'
// import { request } from 'modules/helpers';

/**
 * Login
 */
// const urlApi = 'http://localhost:3333/api/';

export function* auth({ payload }) {
  try {
    const response = yield call(request, 'post', `/auth/login`, payload)
    const {
      data: { data },
    } = response
    /* disini ya */
    // if (payload?.remember_me) /* jika remember_me di centang maka login akan selamanya */
    localStorage.setItem('authToken', JSON.stringify(data))
    // else
    // bake_cookie('app_login', data?.token, 1);
    /* jika remember_me tidak di centang maka login akan 1 jam */

    // mengambil isi user setting ..
    yield put({
      type: ActionTypes.SETTING_STATE,
      // payload: response
    })
    /* simpan login cookie */

    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: data,
    })

    yield put({
      type: ActionTypes.PROFILE_SET_SUCCESS,
      payload: data,
    })
  } catch (err) {
    /* istanbul ignore next */
    console.log(err)

    const message =
      err.response !== undefined ? err.response.data : { message: 'Terjadi galat pada server' }

    yield put({
      type: ActionTypes.USER_AUTH_FAILURE,
      payload: message,
    })

    localStorage.setItem('authToken', '')
  }
}

export function* logOut() {
  try {
    const res = yield call(request, 'post', `/auth/logout`)
    const data = res.data !== undefined ? res.data : 'error'

    // console.log(res);

    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
      payload: data,
    })

    // delete_cookie('app_login');

    yield put({
      type: ActionTypes.RESET_SETING_STATE,
      payload: data,
    })

    yield put({
      type: ActionTypes.PROFILE_SET_RESET,
      payload: data,
    })

    yield put({
      type: ActionTypes.REQUEST_RUN_RESET,
    })

    localStorage.setItem('authToken', '')
    // Simpan('authToken', '');
  } catch (err) {
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: '',
    })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_AUTH, auth),
    takeLatest(ActionTypes.USER_LOGOUT, logOut)])
}
