import React from 'react'
import { formatRupiah, /* toTanggal */ } from 'src/module/helpers'
import { SCRIPT_REGEX } from '../../components/PrintPreview/DownloadAndPrint'
import { SupendLoading } from '../../App'

export function nl2br(str) {
  if (!str) return
  const _str = str?.replace(SCRIPT_REGEX, '')
  return _str?.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

const NotaIsText = React.lazy(() => import(/* webpackPrefetch: true */ './NotaIsText'))

const Panel = ({ data, params }) => {
  // const _logo = /* appSetting?.logo ? `${config.baseUrl}${appSetting.logo}` : */ logo
  const akun_bank = nl2br(data?.akun_bank)

  const setDangerHtml = (html) => {
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>
  }
  // console.log(data)

  // const ppn = Math.ceil(0.11 * data?.tarif_layanan)
  // const jatuh_tempo = moment(data.tanggal_invoice).add(data?.jeda_jatuh_tempo, 'day')

  // const total_bayar = data?.enabled_ppn === 2 ? data?.tagihan + ppn : data?.tagihan

  // console.log(params)

  // const is_belum_bayar = params?.is_belum_bayar

  return (
    <React.Fragment>
      <style jsx="true">
        {`
          @media print {
            .invoice::after {
              content: ${`'Copyright ® ${new Date().getFullYear()} ${'SKS BNA Release Version 0.016'}'`};
              font-size: 10px;
              position: absolute;
            }
          }
        `}
      </style>
      {params?.is_text_only && (
        <React.Suspense fallback={SupendLoading}>
          <NotaIsText params={params} data_tagihan={data}></NotaIsText>
        </React.Suspense>
      )}
      {!params?.is_text_only && (
        <React.Fragment>
          <section
            className="invoice reg_pelanggan"
            style={!params?.is_text_only ? { pageBreakAfter: 'always' } : {}}
          >

            <div className="row">
              <div className="col-6">
                Kepada
                <br />
                ID Pelanggan : {data?.id_pelanggan}
                <br />
                Nama Pelanggan: {data?.nama_lengkap}
                <br />
                Alamat: {data?.alamat}
                <br />
                Bln/Th : {data?.nama_bulan}/{data?.tahun}
              </div>

              {/* {!params?.is_belum_bayar && (
                <div className="col-6">
                  <strong>STATUS PEMBAYARAN</strong>
                  <h1
                    className={
                      data?.status === 'Lunas'
                        ? 'text-danger fw-bolder mb-1'
                        : 'text-danger fw-bolder mb-1'
                    }
                  >
                    {data?.status === 'Lunas' ? 'PAID' : 'UNPAID'}
                  </h1>
                </div>
              )} */}
            </div>

            <div className="text-center mb-2 mt-2 p-2">
              <strong>INVOICE</strong>
            </div>
            <table className="table table-sm table-borderless ">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Produk</th>
                  <th>Tarif</th>
                  {data?.enabled_ppn === 2 && <th>PPN(11%)</th>}
                  <th width="100">Diskon</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-bottom border-light">1</td>
                  <td className="border border-bottom">{data?.nama_layanan}</td>
                  <td className="border border-bottom">{formatRupiah(data?.tagihan, true)}</td>

                  {data?.enabled_ppn === 2 && (
                    <td className="border border-bottom" style={{ width: '9rem' }}>
                      {formatRupiah(data?.nominal_ppn, true)}
                    </td>
                  )}
                  <td className="border border-bottom">-</td>
                  <td className="border border-bottom">
                    {formatRupiah(data?.total_tagihan, true)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} className="text-end">
                    <strong>TOTAL PEMBAYARAN</strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={data?.enabled_ppn === 1 ? 3 : 4}></td>
                  <td colSpan={1}>Sub total</td>
                  <td colSpan={1}>{formatRupiah(data?.total_tagihan, true)}</td>
                </tr>
                <tr>
                  <td colSpan={data?.enabled_ppn === 1 ? 3 : 4}></td>
                  <td colSpan={1}>Total</td>
                  <td colSpan={1}>{formatRupiah(data?.total_tagihan, true)}</td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              {data?.enabled_ppn === 1 ? (
                <small>*Tagihan yang dibayarkan sudah termasuk pajak 11%</small>
              ) : (
                <span></span>
              )}
            </div>
            <div>
              <strong>Rekening Pembayaran</strong>
              {setDangerHtml(akun_bank)}
            </div>

            <div className="d-flex justify-content-end">
              <div className="p-2">
                <div className="garis-solid border-primary"></div>
                <strong>FINANCE</strong>

                {/* <strong>{data?.kepala_kantor}</strong> */}
              </div>
            </div>
            <div>
              * Note
              <br />
              <span>{data?.note && nl2br(data?.note)}</span>
            </div>

            <br />
            <br />
            {/* <small> Dicetak pada tanggal : {toTanggal(new Date(), 'DD MMMM YYYY')}</small>
            <p>Terimakasih</p> */}
          </section>

          <br />
          <div className="row">
            <div className="col-6">
              <table></table>
            </div>
            <div className="col-6">
              <table></table>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default React.memo(Panel)
